import router from '@/router';
import Vue from 'vue';
import { IS_TRANSLATE_TO_EN } from '@/utils/constants';

const mixins = {
  computed: {
    isShowLanguageSelection() {
      return String(IS_TRANSLATE_TO_EN) === 'true';
    },
    styleContent() {
      const height = window.innerHeight - 310;
      return {
        'min-height': `${height}px`,
      };
    },
    defaultTableStyles() {
      const height = window.innerHeight - 430;
      return {
        height: `${height}px`,
        overflow: 'hidden',
      };
    },
  },
  methods: {
    modifyString(str) {
      return str
        .toLowerCase()
        .replaceAll('.', '')
        .replaceAll(',', '')
        .replaceAll('!', '')
        .replace(/ /g, '_');
    },
    confirmModalText(locale, title) {
      if (locale === 'de') {
        return `Sind sie sicher, dass sie '${title}' löschen wollen?`;
      }
      return `Are you sure you want to delete '${title}'?`;
    },
    processTableRows(headers, rows) {
      const filtered = (headers || []).map((item) => {
        if (item.value === 'a_anbieter') {
          const { children, ...rest } = item;
          return rest;
        }
        return item;
      });

      const allHeaders = filtered
        .map((h) => {
          if (!h.children) return { value: h.value };
          const last = h.children.length - 1;
          return h.children?.map((c, index) => {
            if (last === index) {
              return {
                ...{ value: c.value },
                ...{ className: 'border-child-right' },
              };
            } else {
              return { value: c.value };
            }
          });
        })
        .flat(1);
      const updatedValues = rows.map((row) => {
        let o = { ...row };
        allHeaders.map((header) => {
          if (!row[header.value]) {
            o = { ...o, ...{ [header.value]: '-' } };
          }
          if (
            header.value.includes('produkt_effektivzins_old') &&
            !row[header.value] &&
            !isFinite(+row[`${header.value[0]}_produkt_sollzins`])
          ) {
            o = {
              ...o,
              ...{ [header.value]: row[`${header.value[0]}_produkt_sollzins`] },
            };
          }
          if (
            header.value.includes('produkt_effektivzins') &&
            !isFinite(+row[`${header.value[0]}_produkt_effektivzins_old`]) &&
            isFinite(+row[`${header.value[0]}_produkt_sollzins`])
          ) {
            o = {
              ...o,
              ...{
                [`${header.value[0]}_produkt_effektivzins_old`]: '',
              },
            };
          }
          if (
            header.value.includes('produkt_effektivzins') &&
            !isFinite(+row[`${header.value[0]}_produkt_effektivzins`]) &&
            isFinite(+row[`${header.value[0]}_produkt_effektivzins_old`]) &&
            isFinite(+row[`${header.value[0]}_produkt_effektivzins_difference`])
          ) {
            const result =
              +row[`${header.value[0]}_produkt_effektivzins_old`] +
              +row[`${header.value[0]}_produkt_effektivzins_difference`];
            o = {
              ...o,
              ...{
                [`${header.value[0]}_produkt_effektivzins`]:
                  String(result.toFixed(2)) || '-',
              },
            };
          }
        });
        return o;
      });
      return updatedValues.map((row) => {
        const cols = [];
        for (let i = 0; i < allHeaders.length; i += 1) {
          const key = `${allHeaders[i].value}_difference`;
          const icon = `${allHeaders[i].value}_trend`;
          if (
            !allHeaders[i].value.includes('produkt') &&
            !allHeaders[i].value.includes('anbieter')
          ) {
            const col = {
              isDynamic: true,
              value: row[allHeaders[i].value] || '',
              header: allHeaders[i].value,
              className: allHeaders[i].className,
              compare: row.hasOwnProperty(`${allHeaders[i].value}_difference`)
                ? { [key]: row[key], [icon]: row[icon] }
                : null,
            };
            if (`${allHeaders[i].value}_en` in row) {
              Object.assign(col, {
                value_en: row[`${allHeaders[i].value}_en`],
              });
            }
            cols.push(col);
          } else {
            const col = {
              isDynamic: false,
              value: row[allHeaders[i].value] || '',
              header: allHeaders[i].value,
              className: allHeaders[i].className,
              compare: row.hasOwnProperty(`${allHeaders[i].value}_difference`)
                ? { [key]: row[key], [icon]: row[icon] }
                : null,
            };
            if (`${allHeaders[i].value}_en` in row) {
              Object.assign(col, {
                value_en: row[`${allHeaders[i].value}_en`],
              });
            }
            cols.push(col);
          }
        }
        return cols;
      });
    },
    getHeadersForXLS(headers, rows) {
      return headers.map((header) => {
        if (header?.children) {
          const childrenHeaders = [];
          const filtered = header.children.filter(
            (item) => !item.value.includes('anbieter_kreditgeber')
          );
          filtered.forEach((item) => {
            if (item.text) {
              childrenHeaders.push({
                text: item.text,
                text_en: item.text_en,
                value: item.value,
              });
              if (rows.some((row) => row.hasOwnProperty(`${item.value}_old`))) {
                childrenHeaders.push({
                  text: `${item.text} \n alt`,
                  text_en: `${item.text_en} old`,
                  value: `${item.value}_old`,
                });
              }
              if (
                rows.some((row) =>
                  row.hasOwnProperty(`${item.value}_difference`)
                )
              ) {
                childrenHeaders.push({
                  text: `${item.text} \n diff`,
                  text_en: `${item.text_en} \n diff`,
                  value: `${item.value}_difference`,
                });
              }
            }
          });
          return {
            ...header,
            children: childrenHeaders,
          };
        }
        return header;
      });
    },
    setMenuItems(report, page) {
      if (page === 'singleReport') {
        const menuItems = [
          {
            title: 'common.noteTagsHint.note',
            icon: 'mdi-note-text',
            cardTitle: 'common.noteTagsHint.yourNote',
            hintIcon: 'mdi-information',
            infoText: 'common.noteTagsHint.info.note',
            note: report?.note,
            class: 'note',
            cardClass: 'note-card',
            model: false,
          },
          {
            title: 'common.noteTagsHint.tags',
            icon: 'mdi-tag-outline',
            cardTitle: 'common.noteTagsHint.tags',
            hintIcon: 'mdi-information',
            infoText: 'common.noteTagsHint.info.tags',
            tags: (report?.tags || []).map((tag) => {
              if (tag.includes('deutsche') || tag.includes('erweiterte')) {
                return `${tag?.charAt(0).toUpperCase()}${tag?.slice(1)}`;
              }
              return tag;
            }),
            class: 'tags',
            cardClass: 'tags-card',
            model: false,
          },
          /*{
            title: 'common.noteTagsHint.hint',
            icon: 'mdi-clock',
            cardTitle: 'common.noteTagsHint.hint',
            class: 'hint',
            hint: 'common.noteTagsHint.hintDescription',
            cardClass: 'hint-card',
            model: !!JSON.parse(localStorage.getItem('hint') || 'false'),
          },*/
        ];
        if (+report?.report_type === 1) {
          return menuItems.filter((i) => i.class !== 'hint');
        }
        return menuItems;
      }
      return [
        {
          title: 'common.noteTagsHint.note',
          icon: 'mdi-note-text',
          cardTitle: 'common.noteTagsHint.yourNote',
          note: report?.note,
          class: 'note',
          cardClass: 'note-card',
          model: false,
        },
      ];
    },
    reloadPage() {
      window.location.reload();
    },
    back(to) {
      if (window.location.pathname !== to) {
        router.push(to).catch(() => {});
      }
    },

    updateMenuItemsModel(title, menuItems, isTrue) {
      return menuItems.map((item) => {
        if (item.title === title) {
          return {
            ...item,
            model: isTrue || false,
          };
        }
        return {
          ...item,
          model: false,
        };
      });
    },
  },
};

Vue.mixin(mixins);

export default mixins;
