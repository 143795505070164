import { instance } from '@/utils';
import {
  ADDITIONAL_HEADERS_BANKING_PROFILE,
  DEFAULT_HEADERS_BANKING_PROFILE,
  PROFILE_DATA_FIELDS,
  PROFILE_TABLE_DATA_DEMO_DE,
  PROFILE_TABLE_DATA_DEMO_EN,
} from '@/utils/constants';
import { timePeriodOptions } from '@/utils/helpers';

import apolloProvider from '../../apollo/apollo-provider';
import gql from 'graphql-tag';

function getLocale() {
  return JSON.parse(localStorage.getItem('language') || JSON.stringify('de'));
}

function mapped(item, field) {
  const filterCurrency =
    item.finanzdaten?.filter((it) => it?.waehrung === 'EUR') || [];
  return filterCurrency
    .map((it) => {
      if (it[field]) {
        return { jahr: it.jahr, [field]: it[field] };
      }
    })
    .filter((item) => typeof item != 'undefined');
}

function modifyGqlResponse(data) {
  return data.map((item, index) => {
    const eigenkapital = mapped(item, 'eigenkapital');
    const gewinn = mapped(item, 'gewinn');
    const bilanzsumme = mapped(item, 'bilanzsumme');
    const marktkapitalisierung = mapped(item, 'marktkapitalisierung');
    return {
      ...item,
      eigenkapital,
      gewinn,
      bilanzsumme,
      marktkapitalisierung,
      ordinalNumber: index + 1,
    };
  });
}
function modify(data) {
  return data.map((item, index) => {
    return { ...item, ordinalNumber: index + 1 };
  });
}
async function fetchFullDataGraphQl(variables, allHeaders) {
  const fields = [];
  const headersValue = allHeaders.map((header) => header.value);
  headersValue.forEach((header) => {
    fields.push(PROFILE_DATA_FIELDS[header]);
  });
  const locale = getLocale();
  if (locale === 'en') {
    Object.assign(variables, { lang: 'EN' });
  }
  const QUERY = gql`
      query GetAnbieter($zeitraum: [Int], $standorte: [String], $regionen: [String], $boersennotiert: Boolean, $lang: String) {
          anbieterprofile(zeitraum: $zeitraum, standorte: $standorte, regionen:$regionen, boersennotiert: $boersennotiert, lang: $lang ) {
              data {
                  id
                  anbieterLogo
                  landname
                  finanzdaten {
                      jahr
                      waehrung
                      gewinn
                      eigenkapital
                      marktkapitalisierung
                      bilanzsumme
                  }
                  ${fields.join('\n')}
              }
          }
      }
  `;
  const res = await apolloProvider.defaultClient.query({
    query: QUERY,
    variables: variables,
  });

  return modifyGqlResponse(res.data?.anbieterprofile?.data || []);
}
export default {
  async fetchReportByDate({ commit }, payload) {
    commit('setLoadingByDate', true);
    const { note, title, slug, config } = payload;
    try {
      await instance.get(`/api/bankprofile-data/${slug}/`).then((res) => {
        const { data, ...rest } = res.data;
        const rows = modify(data.data);
        const result = Object.assign(
          rest,
          {
            config,
            rows,
            slug: '',
            note,
            title,
          },
          {}
        );
        commit('setLoadingByDate', false);
        commit('setSingleReport', result);
      });
    } catch (e) {
      commit('error', true);
      setTimeout(() => {
        commit('error', false);
      }, 3000);
      console.log(e.response);
    }
  },
  async fetchAvailableDates({ commit }, date) {
    try {
      await instance.get('api/bankprofile-data/').then((res) => {
        commit('setAvailableDates', { date: date, data: res.data });
      });
    } catch (e) {
      commit('error', true);
      setTimeout(() => {
        commit('error', false);
      }, 3000);
    }
  },
  async updateExistingReport({ commit }, data) {
    const { field, byDate, ...rest } = data;
    const payload = {};

    if (field === 'title') {
      Object.assign(payload, { title: rest.title });
    }
    if (field === 'note') {
      Object.assign(payload, { note: rest.note });
    }

    if (field === 'config') {
      Object.assign(payload, { config: rest.config });
    }

    try {
      await instance.patch(`/api/bankprofile-config/${rest.slug}/`, payload);
    } catch (e) {
      commit('error', true);
      setTimeout(() => {
        commit('error', false);
      }, 3000);
    }
  },
  async fetchSingleReport({ commit }, slug, vm) {
    commit('loading', true);
    try {
      let rows = [];
      const user = JSON.parse(
        localStorage.getItem('user') || JSON.stringify({})
      );

      if (user.isDemoUser) {
        const config =
          getLocale() === 'de'
            ? PROFILE_TABLE_DATA_DEMO_DE
            : PROFILE_TABLE_DATA_DEMO_EN;
        rows = modifyGqlResponse(config);
      } else {
        const period = {
          zeitraum: timePeriodOptions().years.map((item) => +item),
          lang: 'EN',
        };
        const headers = [...DEFAULT_HEADERS_BANKING_PROFILE]
          .filter((header) => header.value !== 'ordinalNumber')
          .concat(ADDITIONAL_HEADERS_BANKING_PROFILE);
        rows = await fetchFullDataGraphQl(period, headers, vm);
      }

      instance.get(`/api/bankprofile-config/${slug}/`).then((res) => {
        const result = Object.assign(res.data, { rows });
        commit('setSingleReport', result);
      });
    } catch (e) {
      commit('error', true);
      setTimeout(() => {
        commit('error', false);
      }, 3000);
      console.log(e.response);
    }
  },
  async fetchAllReports({ commit }, vm) {
    try {
      commit('loading', true);
      const user = JSON.parse(
        localStorage.getItem('user') || JSON.stringify({})
      );
      let rows = [];
      const reports = await instance
        .get('/api/bankprofile-config/')
        .then((res) => {
          const result = res.data.map((item) => {
            return {
              ...item,
              headers: DEFAULT_HEADERS_BANKING_PROFILE,
              rows: [],
            };
          });
          commit('setReports', result);
          return result;
        });

      if (user?.isDemoUser) {
        const config =
          getLocale() === 'de'
            ? PROFILE_TABLE_DATA_DEMO_DE
            : PROFILE_TABLE_DATA_DEMO_EN;
        rows = modifyGqlResponse(config);
      } else {
        const period = {
          zeitraum: timePeriodOptions().years.map((item) => +item),
        };
        const headers = [...DEFAULT_HEADERS_BANKING_PROFILE]
          .filter((header) => header.value !== 'ordinalNumber')
          .concat(ADDITIONAL_HEADERS_BANKING_PROFILE);
        rows = await fetchFullDataGraphQl(period, headers, vm);
      }
      const resultReports = (reports || []).map((report) => {
        return {
          ...report,
          headers: DEFAULT_HEADERS_BANKING_PROFILE,
          rows: rows.slice(0, 4),
        };
      });
      localStorage.setItem('rows', JSON.stringify(rows));
      commit('setReports', resultReports);
      commit('loading', false);
    } catch (e) {
      commit('error', true);
      commit('loading', false);
    }
  },
  async fetchFullBProfile({ commit }, id) {
    commit('loading', true);
    try {
      const user = JSON.parse(
        localStorage.getItem('user') || JSON.stringify({})
      );

      const headers = [...DEFAULT_HEADERS_BANKING_PROFILE]
        .filter((header) => header.value !== 'ordinalNumber')
        .concat(ADDITIONAL_HEADERS_BANKING_PROFILE);
      let rows = [];

      if (user?.isDemoUser) {
        const config =
          getLocale() === 'de'
            ? PROFILE_TABLE_DATA_DEMO_DE
            : PROFILE_TABLE_DATA_DEMO_EN;
        rows = modifyGqlResponse(config);
      } else {
        const period = {
          zeitraum: timePeriodOptions().years.map((item) => +item),
        };
        rows = await fetchFullDataGraphQl(period, headers);
      }
      localStorage.setItem('rows', JSON.stringify(rows));
      const search = rows.find((row) => String(row.id) === id);
      const payload = {
        headers,
        row: search,
      };
      commit('setBankDetails', payload);
    } catch (e) {
      console.log(e, 'error');
      commit('loading', false);
      commit('error', true);
      setTimeout(() => {
        commit('error', false);
      }, 3000);
    }
  },

  async fetchFilteredBankingProfile({ commit }, data) {
    const user = JSON.parse(localStorage.getItem('user') || JSON.stringify({}));
    try {
      if (user?.isDemoUser) {
        const config =
          getLocale() === 'de'
            ? PROFILE_TABLE_DATA_DEMO_DE
            : PROFILE_TABLE_DATA_DEMO_EN;
        return modifyGqlResponse(config);
      } else {
        const locale = getLocale();

        const { headers, ...rest } = data;
        const variables = {};

        if (locale === 'en') {
          Object.assign(variables, { lang: 'EN' });
        }
        const fields = [];
        const allHeaders = headers
          .filter((i) => i.value !== 'ordinalNumber')
          .map((header) => header.value);

        allHeaders.forEach((header) => {
          fields.push(PROFILE_DATA_FIELDS[header]);
        });
        Object.keys(rest).forEach((key) => {
          if (key === 'zeitraum') {
            Object.assign(variables, { [key]: rest[key].map((item) => +item) });
          }
          if (Array.isArray(rest[key]) && key !== 'zeitraum') {
            Object.assign(variables, { [key]: rest[key] });
          }
          if (key !== 'zeitraum' && !Array.isArray(rest[key])) {
            Object.assign(variables, { [key]: rest[key] });
          }
        });

        const QUERY = gql`
            query GetAnbieter($zeitraum: [Int], $standorte: [String], $regionen: [String], $boersennotiert: Boolean, $lang: String) {
                anbieterprofile(zeitraum: $zeitraum, standorte: $standorte, regionen:$regionen, boersennotiert: $boersennotiert, lang: $lang ) {
                data {
                    id
                    anbieterLogo
                    landname
                    finanzdaten {
                        jahr
                        waehrung
                        gewinn
                        eigenkapital
                        marktkapitalisierung
                        bilanzsumme
                    }
                    ${fields.join('\n')}
                }
            }
            }
        `;
        const res = await apolloProvider.defaultClient.query({
          query: QUERY,
          variables: variables,
        });
        return modifyGqlResponse(res.data?.anbieterprofile?.data || []);
      }
    } catch (e) {
      commit('loading', false);
      commit('error', true);
      setTimeout(() => {
        commit('error', false);
      }, 3000);
    }
  },
  createReport({ commit }, payload) {
    try {
      return instance.post('/api/bankprofile-config/', payload);
    } catch (e) {
      commit('error', true);
    }
  },
  deleteReport({ commit }, slug) {
    return instance.delete(`/api/bankprofile-config/${slug}/`);
  },
};
